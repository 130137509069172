import React, {useEffect, useState} from 'react';

const LogIn = ({
    log_in_func,
    isError = false
}) => {

    const [user_name, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    useEffect(() =>{
        if(isError){
            setError(<><p style={{color:'red'}}>Could not verify your username and password.</p></>)
        }

    }, error)

    const log_in = async() => {
        let value = log_in_func(user_name, password)

    }




	return (
		<section>
			<div className='logIn' style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
				
            <div style={{marginTop:'50px', alignSelf:'center', padding:'5px 5px 5px 5px'}}>
                <div style={{alignSelf:'center'}}>
                <div style={{display: 'inline-flex', width:'110px', paddingTop:'10px'}}>
                    Email address
                </div>
                <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <input onChange={(e) => setUsername(e.target.value)}></input>
                </div>
                </div>

                <div style={{alignSelf:'center'}}>
                <div style={{display: 'inline-flex', width:'110px', paddingTop:'10px'}}>
                    Password<br></br>
                </div>
                <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <input onChange={(e) => setPassword(e.target.value)} type='password'></input>
                </div>
                </div>

                <div style={{alignSelf:'center'}}>
                    <div style={{display: 'inline-flex', paddingTop:'10px', width:'90px'}}>
                    <button onClick={(e) => log_in()}>Login</button>
                    </div>

                    <div style={{display: 'inline-flex', paddingTop:'10px'}}>
                    <a href='/reset_req'>Reset password</a>
                    </div>

                </div> 
            
            {error}
         

                </div>
			</div>
         
		</section>
	);
};

export default LogIn;
