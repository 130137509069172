import {api, graph2, graph_vars, graph_vars_str} from '../utils/shared'

export const agents = async(token, fields, vars=[]) => {

    let p = graph_vars_str(vars)

    let vparams = p[0]
    let qparams = p[1]
    let j_params = p[2]

    let q = `query agents ${vparams} {
            agents ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}
