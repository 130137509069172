import { api, graph, graph2, graph_vars, graph_vars_str, getCookie } from '../utils/shared'

export const agent_log_in = async(user_name, password) => {
    return new Promise(async (resolve, reject) => {
        await api(
            "/agent_login",
            {"email_address": user_name, "password": password},
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e)
        );
    });
};


export const client_agent = async() => {
    let u = getCookie('usign', true);
    let q = `{client_agent{result{id email_address first_name second_name enabled contact_number}}}`
    // graph(q, null, (r) =>{
    //     callback(r);
    // }, u.token)

    return new Promise(async (resolve, reject) => {
        await graph(q, null, (r) =>{
            if(r != null){
                resolve(r);
            }
        }, u.token)
    });
}

