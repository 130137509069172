import {graph2, api} from '../utils/shared'

export const bb_name_from_code = (bb_code) => {

    if(bb_code == 5){
        return 'All inclusive';
    }
    else if(bb_code == 4){
        return 'Full board';
    }
    else if(bb_code == 3){
        return 'Half board';
    }
    else if(bb_code == 2){
        return 'Bed & Breakfast';
    }
    else if(bb_code == 1){
        return 'Room only';
    }
    else{
        return 'n/a';
    }
    
};

export const accommodation = async(accommodation_id, fields, token) => {
    let q = `{
        accommodation(accommodation_id: ${accommodation_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}
