import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {basket_from_reference, session_flight_extras_reference, session_flight_reference, session_rooms_reference, session_transfer_reference, session_package_reference} from '../../service/basket'
import {room_prod_table, flight_prod_table, flight_extra_prod_table, transfer_prod_table, package_prod_table, supplements_table} from '../../service/product'
import { accommodation } from '../../service/accommodation'
import dayjs from 'dayjs';

const BasketView = ({user_token}) => {

	const [basket_reference, setBasketRef] = useState(null);
    const [basket_d, setBasketData] = useState(null);
	const [processing, setProcessing] = useState(true)

	const [basket_flight_d, setBasketFlight] = useState(null);
	const [basket_flight_luggage_d, setBasketFlightLuggage] = useState(null);

	const [basket_room_d, setBasketRoom] = useState(null);

	const [accommodation_d, setAccommodation] = useState(null);

	const [basket_transfers_d, setBasketTransfers] = useState(null);
	const [basket_vehicle_d, setBasketVehicle] = useState(null);

	const [basket_package_d, setBasketPak] = useState(null);


	useEffect(() =>{

		let queryStrings = queryString.parse(window.location.search)
		let refernce = queryStrings.bref;

		let f = "session basket{ lead_person{ id first_name second_name email_address contact_telephone address1 post_code } people{ id first_name second_name gender age date_of_birth } products{ id product_type{ id description } adults children price price_per_person margin discount deposit_price } price deposit_price deposit_due additional_requests discounts{ id code amount calculated_amount } supplements{ description price } }"

		let x = basket_from_reference(user_token, f, refernce).then((r) =>{
			if(r == null){
				return
			}	
			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message);
						return
					}
					alert("Unexpected Error Contact HWT support...");
					return
			}
			if(r.agent_session_from_reference.error != null){
				return
			}
			setBasketData(r.agent_session_from_reference.result.basket);
			setBasketRef(refernce);
		}
		);
    }, [])


	useEffect(() =>{
		if(basket_d == null){
			return
		}
		for(let p of basket_d.products){
			if(p.product_type.id == 0){
				get_room_prod();
			}
			if(p.product_type.id == 1){
				get_flight_prod();
			}
			if(p.product_type.id == 2){
				get_flight_extreas();
			}
			if(p.product_type.id == 3){
				get_transfer_prod();
			}
			if(p.product_type.id == 5){
				get_package_prod();
			}	

		}
	}, [basket_d])

	useEffect(() => {

		if(basket_room_d == null){
			return;
		}

		if(basket_room_d.length == 0){
			return;
		}

		let f = `name address lat long resort {name regions {name destinations {name}}}`

		accommodation(basket_room_d[0].accommodation_id, f, user_token).then((r) => {
			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.accommodation.error != null){
				alert(r.accommodation.error.message)
				return
			}
			setAccommodation(r.accommodation.result)

		})

	}, [basket_room_d])

	const get_flight_prod = () => {

		let f = `id number return_number price provider_price original_price margin discount booking_component{reference} departure_airport{id code} 
		arrival_airport{id code} airline departing arrival return_departing return_arrival`

		for(let bp of basket_d.products){
		
			if(bp.product_type.id != 1){
				continue;
			}

			session_flight_reference(bp.id, basket_reference, f ,user_token).then((r) =>{

				if(r == null){
					return
				}
				if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
				}
				if(r.agent_session_flight_reference.error != null){
					alert(r.agent_session_flight_reference.error.message)
					return
				}
				setBasketFlight(r.agent_session_flight_reference.result)
			});
			
		}
	}

	const get_flight_extreas = () => {

		let f = `id description provider_id price provider_price original_price margin discount`

		session_flight_extras_reference(basket_reference, f ,user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.agent_session_flight_extras_reference.error != null){
				alert(r.agent_session_flight_extras_reference.error.message)
				return
			}
			if(r.agent_session_flight_extras_reference.result.length == 0){
				return
			}

			setBasketFlightLuggage(r.agent_session_flight_extras_reference.result)
		});

	}

	const get_room_prod= () => {

		let f = `id accommodation_id description board board_description room_code booking_component{reference} provider_id 
		price provider_price original_price margin discount start_date`

		session_rooms_reference(basket_reference, f ,user_token).then((r) =>{

			if(r == null){
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.agent_session_rooms_reference.error != null){
				alert(r.agent_session_rooms_reference.error.message)
				return
			}
			setBasketRoom(r.agent_session_rooms_reference.result)
		});
			
	}

	const get_transfer_prod= () => {

		let f = `id vehicle_type description booking_component{reference} price provider_price original_price margin discount`

		for(let bp of basket_d.products){

			if(bp.product_type.id != 3){
				continue;
			}

			session_transfer_reference(bp.id, basket_reference, f ,user_token).then((r) =>{

				if(r == null){
					return
				}
				if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
				}
				if(r.agent_session_transfer_reference.error != null){
					alert(r.agent_session_transfer_reference.error.message)
					return
				}
				setBasketTransfers(r.agent_session_transfer_reference.result)
			});
			
		}
	}


	const get_package_prod= () => {

		let f = `id
		flight {
			outbound_number
			inbound_number
			departure_point {
				name
				code
			}
			arrival_airport {
				name
				code
			}
			outbound_departure
			outbound_arrival
			inbound_departure
			inbound_arrival
			bag_count
		}
		accommodation {
			name
			address
			resort{
				name
				regions{
					name
					destinations{
						name
					}
				}
			}
		}
		rooms {
			provider_id
			board_code
			description
			board_description
			price
			margin
			discount
			provider_price
			booking_component {
				reference
				success
			}
			room_number
		}`

		for(let bp of basket_d.products){

			if(bp.product_type.id != 5){
				continue;
			}

			session_package_reference(basket_reference, f ,user_token).then((r) =>{

				if(r == null){
					return
				}
				if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
				}
				if(r.session_package_reference.error != null){
					alert(r.session_package_reference.error.message)
					return
				}
				setBasketPak(r.session_package_reference.result)
			});
			
		}
	}

	let tbl_view_flights =  null;	
	let tbl_view_flight_extra = null;
	let tbl_view_acc = null;
	let tbl_view_transfer = null;
	let tbl_view_package = null;
	let tbl_total_cost = null;
    let tbl_general = null;
	let tbl_sups = null;

	let customer_rows = []

	if(basket_d == null){
		return
	}


	tbl_sups = supplements_table(basket_d.supplements);

	if(basket_room_d != null && accommodation_d != null){
		tbl_view_acc = room_prod_table(basket_room_d, accommodation_d);
	}

	if(basket_flight_d != null){
		tbl_view_flights = flight_prod_table(basket_flight_d);
	}


	if(basket_flight_luggage_d != null){
		tbl_view_flight_extra = flight_extra_prod_table(basket_flight_luggage_d);
	}


	if(basket_transfers_d != null){
		tbl_view_transfer = transfer_prod_table(basket_transfers_d);
	}

	if(basket_package_d != null){
		tbl_view_package = package_prod_table(basket_package_d);
	}



	let tbl_pax = null;
	if(basket_d.products.length > 0){
		tbl_pax = <table>
		<tbody>
				<tr>
					<td>Adults</td>
					<td>{basket_d.products[0].adults}</td>
				</tr>
				<tr>
					<td>Children</td>
					<td>{basket_d.products[0].children}</td>
				</tr>
			</tbody>
		</table>
	}


	tbl_total_cost = <table>
	<tbody>
			<tr>
				<td>Cost</td>
				<td>{basket_d.price}</td>
			</tr>

		</tbody>
	</table>
	

	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>

                <div className='flight_tbl tbl_sec'>
					{tbl_general}
				</div>

				<div className='flight_tbl tbl_sec'>
					{tbl_view_flights}
				</div>

				<div className='acc_tbl tbl_sec'>
					{tbl_view_acc}
				</div>

				<div className='flight_tbl tbl_sec'>
					{tbl_view_flight_extra}
				</div>

				<div className='transfer_tbl tbl_sec'>
					{tbl_view_transfer}
				</div>

				<div className='pak_tbl tbl_sec'>
					{tbl_view_package}
				</div>

				<div className='supp_tbl tbl_sec'>
					{tbl_sups}
				</div>

				<div className='cost_tbl tbl_sec'>
					{tbl_pax}
				</div>

				<div className='cost_tbl tbl_sec'>
					{tbl_total_cost}
				</div>

			</div>
		</section>
	);
};

export default BasketView;
