
import React from "react";
import styled from 'styled-components'

const Container = styled.div`
  width:100%;
  font-family: "Josefin Sans", sans-serif;
  display:flex;
  justify-content: center;
  align-items:center;
  margin-top: 25px;
`;
const LogoImage = styled.img`
    max-width:130px;
    margin-left:20px; 
`;

const ContainerRight = styled.div`
    margin-left:auto;
`;

const StyleLink = styled.a`
    cursor:pointer;
    &:hover{
        color:blue;
    }
`;

const MenuList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    padding-bottom: 15px;
  

    
`;

const MenuItem = styled.div`
@media (max-width: 1000px) {
    width:100%;
    display: flex;
    justify-content: center;
}
`;

const MobileLogOut = styled.div`

display:none;
@media (max-width: 1000px) {
    width:100%;
    display: flex;
    justify-content: center;
    display: block;
}
`; 

const DesktopLogOut = styled.div`

&:hover {
    background-color: black;
}
&:hover a {
    color:white;
}

display:block;
position: absolute;
right:25px;
top: 25px;
border-radius: 8px;
border: 1px solid grey;
padding: 5px 15px 5px 15px;
color: grey;

@media (max-width: 1000px) {
    display: none;
}
`; 


const Nav = ({
    history, 
    log_out_func,
    user_token,
    links,
    usr_ref,
    role_id,
  }) => {
	

    const navigate = (path) => {
        window.location = path
    }

    const logout = () => {
        log_out_func()
    }

    const nav = (option) => {

        // setSelected(option);
        navigate(option.url);
    }

    // let promo_links = {'url':'/promos', 'name': 'Promotional Codes', 'children': [{'url':'/promos/add', 'name': 'Add', 'children': [{'url': '/promos', 'name': 'Back'}]}]};

    // let links = [{'url': '/', 'name': 'Home', 'children': [{'url': '/bookings', 'name': 'Bookings'} , {'url': '/baskets', 'name': 'Customer Reference'}, 
    // {'url': '/margins', 'name': 'Margins'}, {'url':'/discounts', 'name': 'Discounts'}, {'url':'/deposits', 'name': 'Deposits'}, promo_links]}]


    // let links = [{'url': '/', 'name': 'Home'}, {'url': '/bookings', 'name': 'Bookings'} , {'url': '/baskets', 'name': 'Customer Reference'}, 
    // {'url': '/margins', 'name': 'Margins'}, {'url':'/discounts', 'name': 'Discounts'}, {'url':'/deposits', 'name': 'Deposits'}, {'url':'/promos', 'name': 'Promotional Codes'}]

    let list_links = [];
    let i = 0;
    for(let l of links){

        let active_style = null;
  
      
        if(l.url == window.location.pathname){
            active_style = {color:'blue'};
            list_links.push(<MenuItem key={'lstitem' + i}><StyleLink style={active_style} onClick={() => nav(l)}>{l.name}</StyleLink></MenuItem>)
        }
        else{
            let style = {listStyleType: 'none'};
            if(l.new != null){
                if(l.new){
                    style = {listStyleType: 'none', color: 'green', fontWeight: 'bold'};
                }
            }

            list_links.push(<MenuItem style={style} key={'lstitem' + i}><StyleLink onClick={() => nav(l)}>{l.name}</StyleLink></MenuItem>)
        }
        i++;
    }

    // let logo = null;
    // let userLogo = null;
    // if(usr_ref != null){
    //     userLogo = <LogoImage src={'/images/clogo/' + usr_ref +'/logo.png'}></LogoImage>
    // }
    // else{
    //     logo = <LogoImage src={'/images/logo.png'}></LogoImage>
    // }
   
    return(
        <>
        <Container>

         
  
            
                <div>
               
                <LogoImage src={'/images/logo.png'}></LogoImage>
                  
                </div>
                
     
              
           
       

       
                {/* <div style={{marginRight: '15px'}}>
                    <StyleLink onClick={() => logout()}>Logout</StyleLink>
                </div> */}
         

        </Container>

        <Container style={{borderBottom: '1px solid grey', marginBottom: '15px'}}>

            <MenuList>

                {list_links}

                <MobileLogOut>
                    <MenuItem>
                    <StyleLink onClick={() => logout()}>Logout</StyleLink></MenuItem>
                </MobileLogOut>


                <DesktopLogOut>
                    <MenuItem>
                    <StyleLink onClick={() => logout()}>Logout</StyleLink></MenuItem>
                </DesktopLogOut>

            </MenuList>


            

            {/* <div style={{marginRight: '15px'}}>
                    <StyleLink onClick={() => logout()}>Logout</StyleLink>
                </div> */}

        </Container>
    </>
        
    )
  }

  export default Nav;

