import React, {useEffect, useState} from 'react';
import {bookings, bookingCount} from '../../service/booking'
import {agents as getAgents} from '../../service/agents';
import YMDPicker from '../../components/ymd_picker'
import dayjs from 'dayjs';

const Bookings = ({user_token}) => {

    const [d_view, setDataView] = useState(null);
	const [b_count, setBookingCount] = useState(null);

    const [hwt_booking_id, setHWTID] = useState(null);
	const [booking_ref, setBookingRef] = useState(null);

	const [surname, setSurname] = useState(null);
 
	const [selected_from_date, setSelectedFromDate] = useState(null);
	const [selected_to_date, setSelectedToDate] = useState(null);

	const [selected_dep_from_date, setSelectedFromDepDate] = useState(null);
	const [selected_dep_to_date, setSelectedToDepDate] = useState(null);

	const [selected_agent, setSelectedAgent] = useState(null);
	const [agents, setAgentOptions] = useState([]);

	const [status, setStatus] = useState(null)
	const [hidden, setHidden] = useState(null)

    useEffect(() =>{

		let f = "id date_of_booking first_name second_name adults children duration confirmed cost, agent_name"

		// let test_p = []
		// test_p.push({"param_var_name": "$second_name", "param_name": "second_name", "param_type": "String!", "value": "three"})
		bookings(user_token, f, []).then((r) =>{
			booking_result(r);
		});

		bookingCount(user_token, []).then((c) => {
			setBookingCount(c.agent_booking_count.result);
		});

		let fa = 'id first_name second_name email_address';
		getAgents(user_token, fa, []).then((r) => {

			const aops = [];			
			for(const x of r.agents.result){

				aops.push({"first_name": x.first_name, "second_name":  x.second_name, "id": x.id, "email_address": x.email_address})

			}
			setAgentOptions(aops)
		});



    }, [])
	
	// const page = (start) => {
	// 	//change the view
	// 	let selected_page = start + 1;
	// 	let start_row = 0;
	// 	let end_row = 100;
	// 	if(start > 0){
	// 		start_row = start * 100;
	// 		end_row = start_row + 100;
	// 	}
	
	// 	// bookings(start_row, end_row, user_token, {}).then((r) => {
	// 	// 	setDataView(r[0])
	// 	// 	setDataViewCount(r[1])
	// 	// 	setSelectedPage(selected_page)
	// 	// })

	// 	filter(start_row, end_row, selected_page)

	// }

	const filter = (start, end, selected_page = 1) => {

		let f = "id date_of_booking first_name second_name adults children duration confirmed cost, agent_name"

		// let test_p = []
		// test_p.push({"param_var_name": "$second_name", "param_name": "second_name", "param_type": "String!", "value": "three"})

		let filters = []

		if(hwt_booking_id != null && hwt_booking_id != ''){
			filters.push({"param_var_name": "$hwt_id", "param_name": "hwt_id", "param_type": "Int", "value": Number(hwt_booking_id)})
		}

		if(booking_ref != null && booking_ref != ''){
			filters.push({"param_var_name": "$client_reference", "param_name": "client_reference", "param_type": "String", "value": booking_ref})
		}
		if(surname != null){
			filters.push({"param_var_name": "$second_name", "param_name": "second_name", "param_type": "String!", "value": surname})
		}
		if(selected_from_date != null){

			let from_date = selected_from_date + ' 00:00:00';
			let to_date = selected_to_date;
			if(to_date == null){
				let today_m = dayjs();
				to_date = today_m.format('YYYY-MM-DD')
			}
			to_date = to_date + ' 23:59:59';
			// 2023-02-24 00:00:00 BETWEEN 2023-02-25 00:00
			let v_date = from_date + " BETWEEN " + to_date;
			filters.push({"param_var_name": "$booking_date_range", "param_name": "booking_date_range", "param_type": "String!", "value": v_date})
		}
		if(selected_dep_from_date != null){
			let dep_from_date = selected_dep_from_date + ' 00:00:00';
			let dep_to_date = selected_dep_to_date;
			if(dep_to_date == null){
				let dep_today_m = dayjs();
				dep_to_date = dep_today_m.format('YYYY-MM-DD')
			}
			dep_to_date = dep_to_date + ' 23:59:59';
			// 2023-02-24 00:00:00 BETWEEN 2023-02-25 00:00
			let v_date = dep_from_date + " BETWEEN " + dep_to_date;
			filters.push({"param_var_name": "$product_date_range", "param_name": "product_date_range", "param_type": "String!", "value": v_date})
		}

		if(status != null && status != -1){
			filters.push({"param_var_name": "$confirmed", "param_name": "confirmed", "param_type": "Int", "value": Number(status)})
		}

		if(hidden != null){
			let h = 0;
			if(hidden){
				h = 1;
			}
			filters.push({"param_var_name": "$show_hidden", "param_name": "show_hidden", "param_type": "Int", "value": h})
		}

		if(selected_agent != null){
			filters.push({"param_var_name": "$agent_id", "param_name": "agent_id", "param_type": "Int", "value": selected_agent})
		}

		bookings(user_token, f, filters).then((r) => {
			booking_result(r);
		});		
		
		bookingCount(user_token, filters).then((c) => {
			setBookingCount(c.agent_booking_count.result);
		});
	}

	const booking_result = (r) => {
		if(r == null){
			return
		}	
		if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
		}
		if(r.agent_bookings.error != null){
			alert(r.agent_bookings.error.message)
			return
		}
		setDataView(r.agent_bookings.result)

	}

	const reset_filters = () =>{

		setSelectedAgent(null);
		setHWTID(null);
		setBookingRef(null);
		setSelectedFromDate(null);
		setSelectedToDate(null);
		setSurname(null);
		setSelectedFromDepDate(null);
		setSelectedToDepDate(null);
		setStatus(null);
		setHidden(null);

		let f = "id date_of_booking first_name second_name adults children duration confirmed cost agent_name";
		bookings(user_token, f, []).then((r) => {
			booking_result(r);
		});

		bookingCount(user_token, []).then((c) => {
			setBookingCount(c.agent_booking_count.result);
		});
	}

	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

	const change_dep_from_date = (v) => {
		setSelectedFromDepDate(v)
	}	
	
	const change_dep_to_date = (v) => {
		setSelectedToDepDate(v)
	}

	const change_agent = (agent_id) => {
		if(agent_id == -1){
			setSelectedAgent(null);
			return
		}
		setSelectedAgent(Number(agent_id));
	}

	let tbl_view = null;
	if(d_view != null){

		let rows = []
		for(let x of d_view){

			let date_of_booking = dayjs(x.date_of_booking, 'YYYY-MM-DD HH:mm');
			let str_date_of_booking = date_of_booking.format('DD MMMM YYYY HH:mm');

			let status_class = ''
	

			let str_status = "action required"
			if(x.confirmed){
				str_status = "confirmed"
				status_class = 'booking_confirmed'
			}


			rows.push(<tr className={status_class}>
				<td>
					{x.id}
				</td>
				<td>
					{str_date_of_booking}
				</td>
				<td>
					{x.first_name} {x.second_name}
				</td>
				<td>
					A:{x.adults} C:{x.children}
				</td>
				<td>
					{x.cost} 
				</td>
				<td>
					{x.agent_name} 
				</td>
				<td>
					{str_status} 
				</td>
				<td>
					<a href={'/bookings/view?bid=' + x.id}>View</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						ID
					</td>
					<td>
						Booking Date
					</td>
					<td>
						Lead Name
					</td>
					<td>
						Pax
					</td>
					<td>
						Cost
					</td>
					<td>
						Agent Name
					</td>	
					<td>
						Status
					</td>
					<td>
						
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}
	
	let str_book_ref = ''
	if(booking_ref != null){
		str_book_ref = booking_ref;
	}

	let str_surname = ''
	if(surname != null){
		str_surname = surname;
	}

	let str_hwt_id = ''
	if(hwt_booking_id != null){
		str_hwt_id = hwt_booking_id;
	}

	let filter_agt_ops = null;
	if(agents.length > 0){


		const str_filt_ops = [];

		if(selected_agent == null){
			str_filt_ops.push(<option selected={true} value={-1}>Anyone</option>)
		}
		else{
			str_filt_ops.push(<option value={-1}>Anyone</option>)
		}


		for(const x of agents){
			let sel = false;
			if(x.id == selected_agent){
				sel = true;
			}
			str_filt_ops.push(<option selected={sel} value={x.id}>{x.first_name} {x.second_name}</option>)
		}

		filter_agt_ops = <div className='filter_field'>
		<div>
			Agents
		</div>
		<div>
			<select onChange={(e) => change_agent(e.target.value) }>
				{str_filt_ops}
			</select>
		</div>
	</div>
	}

	return (
		<section className='aboutcontent-box'>
	
		<div style={{display:'flex', flexWrap:'wrap', justifyContent: 'center', margin:'50px 50px 50px 50px', columnGap: '2em', rowGap: '2em'}}>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}


				<div className='fitler_sec'>

					{filter_agt_ops}


					<div className='filter_field'>
						<div>
							HWT Booking ID 
						</div>
						<div>
							<input id='txtHWTBookingID' value={str_hwt_id} onChange={(e) => setHWTID(e.target.value)}></input>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Booking reference
						</div>
						<div>
							<input id='txtBookingRef' value={str_book_ref} onChange={(e) => setBookingRef(e.target.value)}></input>
						</div>
					</div>

					<div style={{height: '105px'}}>
					<div>
						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								Booking 
							</div>
							<div>
								<YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker> 
							</div>
						</div>
						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								To
							</div>
							<div>
								<YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
							</div>
						</div>
					</div>
					</div>

					<div style={{height: '105px'}}>
					<div>

						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								Departing
							</div>
							<div>
								<YMDPicker id='sel_dep_from' key='sel_dep_from_k' initalValue={selected_dep_from_date} onChange={(value) => change_dep_from_date(value)}></YMDPicker> 
							</div>
						</div>

						<div className='filter_field'>
							<div>
								To
							</div>
							<div>
								<YMDPicker id='sel_dep_to' key='sel_dep_to_k' initalValue={selected_dep_to_date} onChange={(value) => change_dep_to_date(value)}></YMDPicker>
							</div>
						</div>

					</div>
					</div>
				

					<div className='filter_field'>
						<div>
							Surname
						</div>
						<div>
							<input id='txtSurname' key='txtSurname' value={str_surname} onChange={(e) => setSurname(e.target.value)}></input>
						</div>
					</div>			

					<div className='filter_field'>
						<div>
							Status
						</div>
						<div>
							<select id='ddlStatus' onChange={(e) =>  setStatus(e.target.value)}>
								<option value={-1}>All</option>
								<option value={1}>Confirmed</option>
								<option value={0}>Action required</option>
							</select>
						</div>
					</div>		

					<div className='filter_field'>
						<div>
							Show Hidden
						</div>
						<div>
							<input id='chkHidden' type="checkbox" onChange={(e) => setHidden(e.target.checked)}></input>
						</div>
					</div>		
					
					<div className='filter_field'>
						<div>
							<button onClick={() => filter(0, 100)}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>
						</div>
					</div>

				</div>

				<div className='sec'>
					Total Count: {b_count}
				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

			</div>
		</section>
	);
};

export default Bookings;
